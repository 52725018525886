// i18next-extract-mark-ns-start privacy

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';

const PrivacyPage = ({
	data,
	pageContext: {
		breadcrumb: { crumbs },
	},
}: {
	data:any;
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
}) => {
	
	return (
		<Page>
			<SEO title="Privacy notice | Realis Simulation" description="" />

			<Banner
				title="Privacy notice"
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<p>
						Effective November 1, 2022
					</p>
					<p>
						Realis Simulation Inc. (&ldquo;Realis&rdquo;) has established a comprehensive privacy programme designed to help us respect and protect your data privacy rights.
						Realis is subject to the investigatory and enforcement authority of the Federal Trade Commission (FTC).
						Realis respects the privacy of visitors to its website. As a result, we have developed this website privacy policy.
						This website privacy policy applies only to the operation of websites that directly link to this policy when you click on &ldquo;privacy policy&rdquo; from the website.
						The term &ldquo;personal information&rdquo; refers to information that can identify you, such as your name, address, telephone number, e-mail address, credit card or
						other similar information (&ldquo;personal information&rdquo;).
						You have the right to access any of this personal information upon request by emailing our Subscription Services Manager at
						{' '} <a href="mailto:contact@realis-simulation.com">contact@realis-simulation.com</a>.
					</p>
					<h2>How we collect information</h2>
					<p>
						We do not collect any personal information from mere visitors to our site.
						We do not collect any personal information unless you provide it.
						We may request this personal information when a product or demo is downloaded, for news and information subscriptions, or when additional information about our
						products or services is requested. Information that is collected: Email, Name, Address, Company, and Industry.
						When you visit our site, some information is also automatically collected through the use of log files,
						such as your computer&rsquo;s Internet Protocol (IP) address, your computer&rsquo;s operating system, the browser type, the address of a referring web Service and your activity on the Service.
						We use this information for purposes such as analyzing trends, administering the Service, improving customer service, diagnosing problems with our servers,
						tracking user movement, and gathering broad demographic information for aggregate use.
					</p>
					<p>
						Please note that any information that you post to a public discussion group, such as the user forum, Realis Community forums,
						is available to all persons accessing that location.
						In addition, you should be aware that some discussion groups offer a service in which subscribers are emailed a copy of all messages posted to the group on a particular topic.
						Consequently, if you post a message to the group, your email address may be sent to those subscribers that have indicated an interest in the topic of your message.
					</p>
					<h2>How we use information</h2>
					<p>
						We store and use personal information provided by you to better understand customer needs,
						as well as to determine how we can improve our products and services.
						With your permission we may also use this personal information to contact you with announcements and promotions or
						with questions about your account that may arise.
						For customers located outside of the United States and Canada, your information may be shared with a third-party distributor
						acting as Realis&rsquo; agent, or shared with our European office located in Germany for customers located in Europe.
						This information is used for the routine purposes of selling Realis products, marketing Realis products,
						and providing support for Realis products.
						Information shared with a Realis distributor is prohibited from being used for any other purpose and is prohibited from being shared with
						any other party. You have the option to limit the sharing of this information or update this preference at any time by
						contacting our Subscription Services Manager at contacts@realis-simulation.com.
					</p>
					<p>
						We also may disclose personal information when we are required to or we believe it is appropriate to comply with the law
						(e.g., a lawful subpoena, warrant or court order); to enforce or apply this privacy policy or our other policies or agreements;
						to initiate, render, bill, and collect for amounts owed to us; to protect our or our customers&rsquo; rights, property or safety;
						to protect our customers from fraudulent, abusive, or unlawful use of our Service;
						or if we believe that an emergency involving the danger of death or serious physical injury to any person requires
						disclosure of communications or justifies disclosure of personal information.
						In addition, information about our customers, including personal information, may be disclosed as part of any merger, acquisition,
						debt financing, sale of Realis assets, as well as in the event of an insolvency, bankruptcy or receivership in which personally
						identifiable information could be transferred to third parties as one of the business assets of Realis.
						Please note that Realis may be required to disclose an individual&rsquo;s personal information in response to a lawful request
						by public authorities, including to meet national security or law enforcement requirements.
					</p>
					<h2>Protecting personal information</h2>
					<p>
						Realis endeavours to secure your personal information from unauthorised access,
						use or disclosure by putting into place physical, electronic and managerial procedures to safeguard the information we collect through the site.
						Please be aware, however, that despite our efforts, no security measures are perfect or impenetrable.
					</p>
					<h2>Non-identifable information</h2>
					<p>
						In some instances, we collect information that is not personally identifiable.
						Examples of this type of information include the type of computer operating system used, the type of Web browser used,
						or the domain name of the website which linked to our site.
						This information helps us analyse trends, administer our site, and gather broad demographic information for aggregate use.
						We do not share, rent, or sell non-identifiable information.
					</p>
					<h2>Web cookies</h2>
					<p>
						When you view our website, we may store a small amount of data on your computer.
						This data is in the form of a “cookie.” It can only be read by the server that sent it.
						Cookies act as a way to record passwords, orders, and preferences for visiting our site.
						In addition, cookies allow us to remember you across pages of our website or multiple visits.
						This saves you time by eliminating the need to re-enter information each time you access a password-protected page.
						Most Web browsers allow you to erase cookies, block cookies, or receive a warning before a cookie is stored.
						Refer to your browser&rsquo;s instructions or help screen to learn more about these functions.
					</p>
					<h2>Web usage analytics</h2>
					<p>
						Our site uses analytics tools to help analyze how users use the site. The tools use cookies.
						The information generated by the cookies about your use of the site may include IP address, number of visits, page views, and time on site.
						This information is then used to evaluate visitors&rsquo; use of the site and to compile statistical reports for Realis.
					</p>
					<p>
						We will not allow any third party to use the statistical analytics tools to track or collect any personal information or
						to associate any data gathered from the site with any personal information from any source.
					</p>
					<h2>Declining email offers</h2>
					<p>
						You may unsubscribe from a particular email list at any time by selecting the &ldquo;Manage Subscriptions&rdquo; option within the body of the email.
						All email offers that you receive from Realis inform you of how to decline receiving further offers.
					</p>
					<h2>Changes to policy</h2>
					<p>
						We reserve the right to change the provisions of this privacy policy at any time by posting a revised privacy policy on the site
						and indicating on the privacy policy the date it was last updated.
						Your use of the Service following the posting of such changes or revised statement shall constitute your acceptance of any such changes.
						We encourage you to review our privacy policy whenever you use our Service to determine if any changes have been made
						and to make sure that you understand how any personal information you provide will be used.
					</p>
					<h2>Contact us</h2>
					<p>
						We take safeguarding your privacy very seriously and Realis acknowledges the individual&rsquo;s
						right to access their personal data. If you wish to verify, correct or delete any personal information we have collected or
						if you have any questions or concerns, or would like to be removed from a mailing list,
						please contact our Subscription Services Manager at
						{' '} <a href="mailto:contact@realis-simulation.com">contact@realis-simulation.com</a>.
					</p>
					<p>
						Note: Realis is not responsible for the content or privacy practices of non-Realis websites to which this site may link.
						Review the privacy policy of such sites before use.
					</p>
					<p>
						Regarding both Data Privacy and Website Privacy, Realis follows the following principles:
					</p>
					<h3>Notice</h3>
					<p>
						Notice will be provided in clear and conspicuous language when individuals are first asked to provide personal information to
						Realis or as soon thereafter as is practicable, but in any event before Realis uses such information for a purpose other
						than that for which it was originally collected or processed or disclosed for the first time to a third party.
					</p>
					<h3>Choice</h3>
					<p>
						Realis will offer individuals the opportunity to choose (opt out) whether their personal information is (i) to be disclosed to a
						third party or (ii) to be used for a purpose that is materially different from the purpose(s)
						for which it was originally collected or subsequently authorized by the individuals.
						Individuals shall be provided with clear, conspicuous, and readily available mechanisms to exercise choice.
					</p>
					<p>
						It is not necessary for Realis to provide choice when disclosure is made to a third party that is acting as an agent to perform task(s) on behalf of and under the instructions of the organization.
						However, Realis shall always enter into a contract guaranteeing the same level of privacy protection as outlined within this statement with the agent.
					</p>
					<p>
						Realis does not collect sensitive information (i.e., personal information specifying medical or health conditions, racial or ethnic origin, political opinions,
						religious or philosophical beliefs, trade union membership or information specifying the sex life of the individual).
						Such collection would require affirmative express consent (opt in) from individuals if such information is to be (i) disclosed to a third party or (ii)
						used for a purpose other than those for which it was originally collected or subsequently authorized by the individuals through the exercise of opt-in choice.
						Realis treats any personal information received from a third party as sensitive, where the third party identifies and treats it as sensitive.
					</p>
					<p>
						For details please see the &lsquo;How we use information&rsquo; above.
					</p>
					<h3>Accountability for onward transfer</h3>
					<p>
						As part of Realis&rsquo; transfer of personal data to a third party distributor acting as an agent,
						Realis : (i) transfers such data only for limited and specified purposes; (ii) ascertains that the agent is obligated to provide
						at least the same level of privacy protection as is required by the Principles;
						(iii) takes reasonable and appropriate steps to ensure that the agent effectively processes the personal information transferred
						in a manner consistent with Realis&rsquo; obligations under the Principles;
						(iv) requires the agent to notify Realis if it makes a determination that it can no longer meet its
						obligation to provide the same level of protection as is required by the Principles; (v) upon notice, including under (iv),
						take reasonable and appropriate steps to stop and remediate unauthorized processing; and (vi)
						provide a summary or a representative copy of the relevant privacy provisions of its contract with that agent to the Department upon request.
					</p>
					<h3>Security</h3>
					<p>
						Realis takes reasonable and appropriate measures to protect any personal information collected from loss, misuse and unauthorized access,
						disclosure, alteration, and destruction, taking into due account the risks involved in the processing and the nature of the personal data.
					</p>
					<h3>Data integrity and purpose limitation</h3>
					<p>
						Consistent with the Principles, personal information must be limited to the information that is relevant for the purposes of processing.
						Realis will not process personal information in a way that is incompatible with the purposes for which it has been collected or
						subsequently authorized by the individual.
						To the extent necessary for those purposes, Realis will take reasonable steps to ensure that personal data is reliable for its intended use,
						accurate, complete, and current. Realis will adhere to the Principles for as long as it retains such information.
					</p>
					<p>
						Information may be retained in a form identifying or making identifiable the individual only for as long as it serves a purpose of
						processing within the meaning of the paragraph above.
						This obligation does not prevent Realis from processing personal information for longer periods for the time and to the extent such
						processing reasonably serves the purposes of archiving in the public interest, journalism, literature and art, scientific or historical
						research, and statistical analysis. In these cases, such processing shall be subject to the other Principles and provisions of the Framework.
						Realis takes reasonable and appropriate measures in complying with this provision.
					</p>
					<p>
						Depending on the circumstances, examples of compatible processing purposes may include those that reasonably serve customer relations,
						compliance and legal considerations, auditing, security and fraud prevention, preserving or defending Realis&rsquo; legal rights,
						or other purposes consistent with the expectations of a reasonable person given the context of the collection.
						In this context, if, given the means of identification reasonably likely to be used (considering, among other things, the costs of
						and the amount of time required for identification and the available technology at the time of the processing)
						and the form in which the data is retained, an individual could reasonably be identified by Realis,
						or a third party if it would have access to the data, then the individual is “identifiable.”
					</p>
					<h3>Access</h3>
					<p>
						Realis acknowledges the right of individuals to have access to their personal information.
						Individuals shall have access to the personal information about them that Realis holds and are able to review, correct, amend,
						or delete that information where it is inaccurate, or has been processed in violation of the Principles, except where the burden or expense of providing
						access would be disproportionate to the risks to the individual&rsquo;s privacy in the case in question, or where the rights of persons other than the
						individual would be violated.
						Individuals can access this personal information via their RS Account on their profile page or by contacting the Subscription Services Manager at
						{' '} <a href="mailto:contact@realis-simulation.com">contact@realis-simulation.com</a>.
					</p>
					<h3>Recourse, enforcement, and liability</h3>
					<p>
						Individuals with inquiries or complaints regarding this privacy policy should first contact Realis at:
					</p>
					<p>
						Realis Simulation Inc.<br />
						3535 Factoria Blvd SE, Suite 550<br />
						Bellevue, WA 48006<br />
						USA<br />
					</p>
				</div>
			</PageSection>
		</Page>
	);
};

export default PrivacyPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["index", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
			banner: file(relativePath: { eq: "content/banner-connected.png" }) {
				...imageCarousel
			}
	}
`